/*** SCROLL TO TOP *******************************/
.scrollToTop {

  @include bp(max,$normal) { display: none; }
  @include bp(min,$normal) {
    opacity: 0;

    position: fixed;
    right: 20px;
    bottom: -100px;
    z-index: 100;
    width: 75px;
    height: 75px;
    line-height: 90px;

    color: $blanc;
    font-size: 40px;
    text-align: center;

    background: #dedede;
    border-radius: 5px;
    transition: $transition-base;

    &:before {
      content: "\E004";
      font-family: "iconfont";
    }
    &:hover {
      background: #656565;
      cursor: pointer;
    }
  }

}
.scrollToTop--show {
  opacity: 1;
  bottom: 20px;
}