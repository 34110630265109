@keyframes animsympa {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

h2,
h3{
	margin: 0;
}

body{
	background: url(../img/bg/bg_stripes.png);
}

.bonjour {
	display: inline-block;
	animation: animsympa 3s infinite;
	animation-timing-function: linear;
	&:after {
		content: "\1F602";
	}
}

body{
	font-family: $font-family;
}
a{
	@include lazylinks($dark-grey);
}
.wrap1200{
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	.region-content{
		width: 100%;
	}
	@include bp(min, $laptop){
		max-width: 1200px;
		.region-content{
    		max-width: 900px;
		}
	}
}
.wrap900{
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	@include bp(min, $laptop){
		max-width: 900px;
	}
}

ul{
	li.expanded,
	li.collapsed,
	li.leaf{
		list-style-type: none;
		list-style-image : none;
		margin: 0;
			padding: 0
	}
}

@mixin wrap1200{
   width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

/* Titres */
.title-picto{
	display: block;
	position: relative;
	margin: 25px 0;
	svg{
		position: relative;
		top: 4px;
		max-width: 40px;
		max-height: 40px;
	}
	.h2{
		display: inline-block;
		margin-left: 5px;
	}
}
.h2{
	margin: 0 0 12px 0px;
	color: $dark-grey;
	font-family: $font-rob-reg;
	font-weight: 400;
	@include fontsize(25);
	@include lineheight(23);
	@include bp(min, 769px){
		margin: 0 0 8px 12px;
		@include fontsize(38);
		line-height: normal;
	}
}
.h3{
	text-transform: uppercase;
	@include fontsize(20);
}
.young{
	.bg-colored{
		background-color: $orange;
	}
	b{
		color: $orange;
	}
}
.btn-link {
	display: inline-block;
	padding: 12px 20px;
	border: 1px solid $grey;
	@include fontsize(16);
	font-family: $font-title;
	color: $grey;
	background-color: white;
	text-transform: uppercase;
	font-weight: 700;
	@include transition(all 0.3s ease);
	span {
		text-transform: capitalize;
		color: $red-select;
		@include transition(all 0.3s ease);
	}
	&.red{
		background-color: $red-select;
		border: 1px solid $red-select;
	}
	&:hover {
		color: white;
		border: 1px solid $red-select;
		background-color: $red-select;
		span {
			color: white;
		}
	}
}

@mixin btn-link-bg($color){
	border: 1px solid $color;
	background-color: $color;
	color: white;
	&:hover{
		color: $color;
		background-color: white;
	}
}

.swiper-pagination-bullet {
	background: #FFF;
	opacity: 1;
	width: 15px;
	height: 15px;
}
.swiper-pagination-bullet-active,
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #FFF;
	position: relative;
	&:after{
		content: "";
		position: absolute;
		width: 13px;
		height: 13px;
		background-color: $red;
		border-radius: 50%;
		left: 1px;
		top: 1px;
	}
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction{
	width: auto;
	left: auto;
	right: 15px;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
	display: block;
	margin: 8px 0;
}

#block-views-publicites-block-2{
	@include wrap1200;
	margin-bottom: 30px;
}


//Couleurs base, contenu articles, SVG ...
.aqua {
	.ctn-title {
		border-left: 6px solid $aqua;
		.categ {
			color: $aqua;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $aqua;
	}
	.social{
		color: $aqua;
	}
	.bg-colored{
		background-color: $aqua;
	}
	.comment{
		a{
			@include lazylinks($aqua);
		}
	}
	//SVG
	.cls-1{ fill: $aqua; stroke: $aqua;  }
	.cls-2{ stroke: $aqua;  }
	.cls-w{ fill: $aqua; stroke : white;  }
}
.blue {
	.ctn-title {
		border-left: 6px solid $blue;
		.categ {
			color: $blue;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $blue;
	}
	.social{
		color: $blue;
	}
	.bg-colored{
		background-color: $blue;
	}
	.comment{
		a{
			@include lazylinks($blue);
		}
	}
	//SVG
	.cls-1{ fill: $blue; stroke: $blue;}
	.cls-1{ stroke: $blue;}
	.cls-w{ fill: $blue; stroke : white;  }
}
.gold {
	.ctn-title {
		border-left: 6px solid $gold;
		.categ {
			color: $gold;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $gold;
	}
	.social{
		color: $gold;
	}
	.comment{
		a{
			@include lazylinks($gold);
		}
	}
	//SVG
	.cls-1{ fill: $gold; stroke: $gold;}
	.cls-2{ stroke: $gold;}
	.cls-w{ fill: $gold; stroke : white;  }
}
.green {
	.ctn-title {
		border-left: 6px solid $green;
		.categ {
			color: $green;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $green;
	}
	.social{
		color: $green;
	}
	.comment{
		a{
			@include lazylinks($green);
		}
	}
	//SVG
	.cls-1{ fill: $green; stroke: $green;}
	.cls-2{ stroke: $green;}
	.cls-w{ fill: $green; stroke : white;  }
}
.light-blue {
	.ctn-title {
		border-left: 6px solid $light-blue;
		.categ {
			color: $light-blue;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $light-blue;
	}
	.social{
		color: $light-blue;
	}
	.comment{
		a{
			@include lazylinks($light-blue);
		}
	}
	//SVG
	.cls-1{ fill: $light-blue; stroke: $light-blue;}
	.cls-2{ stroke: $light-blue;}
	.cls-w{ fill: $light-blue; stroke : white;  }
}
.orange {
	.ctn-title {
		border-left: 6px solid $orange;
		.categ {
			color: $orange;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $orange;
	}
	.social{
		color: $orange;
	}
	.comment{
		a{
			@include lazylinks($orange);
		}
	}
	//SVG
	.cls-1{ fill: $orange; stroke: $orange;}
	.cls-2{ stroke: $orange;}
	.cls-w{ fill: $orange; stroke : white;  }
}
.purple,
.violet{
	.ctn-title {
		border-left: 6px solid $purple;
		.categ {
			color: $purple;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $purple;
	}
	.social{
		color: $purple;
	}
	.comment{
		a{
			@include lazylinks($purple);
		}
	}
	//SVG
	.cls-1{ fill: $purple; stroke: $purple;}
	.cls-2{ stroke: $purple;}
	.cls-w{ fill: $purple; stroke : white;  }
}
.red {
	.ctn-title {
		border-left: 6px solid $red-select;
		.categ {
			color: $red-select;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $red-select;
	}
	.social{
		color: $red-select;
	}
	.comment{
		a{
			@include lazylinks($red-select);
		}
	}
	//SVG
	.cls-1{ fill: $red-select; stroke: $red-select;}
	.cls-2{ stroke: $red-select;}
	.cls-w{ fill: $red-select; stroke : white;  }
}
.yellow {
	.ctn-title {
		border-left: 6px solid $yellow;
		.categ {
			color: $yellow;
		}
	}
	.reservation,
	.nav-evt a:hover{
		background-color: $yellow;
	}
	.social{
		color: $yellow;
	}
	.comment{
		a{
			@include lazylinks($yellow);
		}
	}
	//SVG
	.cls-1{ fill: $yellow; stroke: $yellow;}
	.cls-2{ stroke: $yellow; }
	.cls-w{ fill: $yellow; stroke : white;  }
}

span.red{
	color: $red-select;
}

//Masque couleur articles
.field-name-field-couleur-article{
	display: none;
}

//triple bloc home et side bar
#block-wikmotion-wikmotion-triple-block,
#block-wikmotion-m-wikmotion-m-triple-block,
#block-wikmotion-wikmotion-triple-block-sidebar,
#block-wikmotion-m-wikmotion-m-triple-block-sidebar {

	//pictos play store et app store
	.android{
		a{
			background-image: url(../img/picto/logo_google_play.png);
		}
	}
	.appstore{
		a{
			background-image: url(../img/picto/picto_appstore.svg);
			background-position: right;
		}
	}

	.android,
	.appstore{
		width: 148px;
		margin-top: 12px;
		margin: 20px auto 0 auto;
		a{
			display: block;
			height: 46px;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

}

//Appli wik
#bloc-appli{
	padding: 16px 14px 14px 14px;
	border: 1px solid $lighty-grey;
	.description{
		position: relative;
		padding-left: 50px;
		@include upperColor($dark-grey, 13);
		letter-spacing: 0.47rem;
		font-weight: 700;
		b{
			@include fontsize(22);
			letter-spacing: normal;
		}
		&:before{
			content: "";
			display: inline-block;
			position: absolute;
			width: 46px;
			height: 46px;
			background: url(../img/picto/logo_wik_n.png) no-repeat center;
		}
	}
	@include bp(min, $tablet){
		.description{
			margin-bottom: 30px;
		}
	}
}


//Bloc abo newsletter
#block-newsletter{
	text-align: center;
	padding: 10px 14px;

	.title{
		@include fontsize(20);
		font-family: $font-rob-reg;
		text-align: center;
		margin-bottom: 10px;
	}
	.grand{
		@include fontsize(25);
		font-weight: 700;
		font-family: $font-title;
	}
	.description{
		text-align: center;
		@include fontsize(13);
		margin-bottom: 8px;
	}

	//Stylisation input et submit
	form{
		position: relative;
	}
	//Input mail
	.form-item-mail{
		@include flexbox;
		flex-wrap: wrap;
		justify-content: center;
		@include calc(width, '100% - 32px');
		position: relative;
		margin: 0;
		//Triangle submit
		&:after{
			content: "";
			width: 32px;
			height: 30px;
			display: inline-block;
			position: absolute;
			top: 0px;
			right: -32px;
			background: $red-select url(../img/picto/picto_triangle_white.svg) no-repeat;
			background-size: 10px;
			background-position: center;
		}
	}
	#edit-mail{
		width: 100%;
		padding: 6px 10px;
		border: 1px solid $light-grey;

	}
	//btn submit
	.form-submit{
		position: absolute;
		right: 0;
		top: 0;
		width: 32px;
		height: 32px;
		color: transparent;
		background-color: transparent;
		border: none;
		z-index: 10;
		cursor: pointer;
	}
	//Choix NL
	.form-type-checkboxes{
		margin: 0;
	}
	.form-type-checkbox{
		text-align: center;
	}
	//Masque label courriel
	.form-item-mail label{
		display: none;
	}

	@include bp(min, $tablet){
		text-align: left;
		.title{
			@include fontsize(22);
		}
		.grand{
			@include fontsize(30);
		}
	}
}

#block-newsletter{
	background-color: $lighty-grey;
}


#block-jeux{
	padding: 24px;
	position: relative;
	background-color: $dark-grey;
	color: white;
	overflow: hidden;
	&:before{
		content: "";
		width: 64%;
		height: 100%;
		position: absolute;
		background-color: rgba(202, 202, 202, 0.15);
		right: -14%;
		top: 0;
		transform: skew(-20deg);
	}
	.title{
		width: 100%;
		margin-bottom: 15px;
		padding-left: 45px;
		@include upperColor(white, 22);
		text-decoration: underline;
		font-family: $font-title;
		&:before{
			content: "";
			display: inline-block;
			position: absolute;
			left: 22px;
			background: url(../img/picto/picto_trefle.png) no-repeat;
			width: 28px;
			height: 28px;
		}
	}
	.link{
		background-color: $red-select;
		font-weight: 700;
		&:before{
			content: "";
			width: 9px;
			height: 9px;
			background: url(../img/picto/picto_triangle_white.svg);
			background-size: cover;
			display: inline-block;
			position: absolute;
			top: 18px;
			left: 15px;
		}
		a{
			@include lazylinks(white);
			@include upperColor(white, 15);
			font-family: $font-title;
		}
	}
	.description{
		width: 70%;
	}
}

input,
select{
	background-color: white;
}



// COVERING
.covering--in {

	.main-content--wrapper {

		max-width: 1200px;
		margin: auto;
		margin-top: 210px;
		background: #fff;
	}
}
