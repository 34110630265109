/*** DEFAULT **********************************************************************************/


/*** BORDER BOX **********************************/
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

/*** CLASSICO ***/
html { font-size: 62.5%; }
body {
  color: $dark-grey;
  font-family: $font-primary; 
  @include fontsize(14);
  font-weight: 400;
  background-color: white;
	@include bp(max, 767px){
  		@include fontsize(15);
	}
}
/*  @include clearfix; */
.clear {
  clear: both;
  overflow: hidden;
}

/*** VRAK **************************************************************************************/
img, embed, object, video {
  max-width: 100%;
  height:auto;
}

button {
  padding: 0; 
  outline : none;
  background: none;
  border: none;
}
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

h1 {
 margin:0;
}

img{
   display: block;
	margin: 0 auto;
}

.wrap900{
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
}

@mixin wrap1200{ 
   width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

@mixin wrap900{ 
   width: 100%;
	max-width: 900px;
	margin: 0 auto;
}