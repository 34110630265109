//breadcrumb
.page-node{
	.breadcrumb{
		position: absolute;
		left: 12px;
		top: 30px;
		margin: 0;
		a{
			@include lazylinks($dark-grey);
		}
	}
}

.intern-aside{
	position: relative;
	@include flexbox;
	@include justify-content(space-between);
	@include flex-wrap(wrap);
	/*background-color: white;*/
	.breadcrumb{
		color : $dark-grey;
		padding: 25px 0 10px 20px;
		@include wrap900;
		max-width: 888px;
		margin-left: 0;
		background-color: white;
		a{
			@include lazylinks($red);
		}
	}
	.field-collection-view-links{
		width: 100%;
	}
	#article{
		.sub-title{
			font-weight: 700;
		}
	}
}


.evenement {
	color : $dark-grey;
	position: relative;
	padding: 100px 12px 0 12px;
	background: white;

	@include bp(min, 1200px){
		margin: 0;
		/*max-width: none;
		@include calc( width, '100% - 280px' ); */
		margin-right: 30px;
	}
	.btn-back,
	.btn-submit{
		position: absolute;
		right: 15px;
		top: 10px;
		display: inline-block;
		padding: 10px 19px;
		border: 1px solid $dark-grey;
		@include fontsize(13);
		font-family: $font-rob-reg;
		color: white;
		background-color: $dark-grey;
		text-transform: uppercase;
		@include transition(all 0.3s ease);
		z-index: 11;
		&:before{
			content: "";
			position: relative;
			top: -1px;
			display: inline-block;
			margin-right: 10px;
			width: 9px;
			height: 9px;
			background: url(../img/picto/picto_triangle_white.svg);
			background-size: cover;
			@include transform(rotate(180deg));
		}
		&:hover{
			color: $dark-grey;
			background-color: white;
			&:before{
				background: url(../img/picto/picto_triangle.svg);
				background-size: cover;
			}
		}
	}
	.ctn-title{
		margin-bottom: 30px;
		.h2{
			margin-left: 12px;
		}
	}


	.categ {
		margin-left: 12px;
		text-transform: uppercase;
		font-family: $font-title;
		@include fontsize(20);
	}
	.ctn-img{
		margin-bottom: 20px;
	}
	img{
		margin: 0;
	}
	.bg-colored{
		display: inline-block;
		color: white;
		text-transform: uppercase;
		font-family: $font-title;
		padding: 7px 5px;
	}
	.social{
		@include flexbox;
		@include align-items(center);
		margin-top: 20px;
		span{
			font-weight: bold;
			@include fontsize(16);
		}
		ul{
			@include flexbox;
			margin: 0 16px 0 12px;
			border-right: 1px solid $light-grey,
		}
		li{
			margin-right: 10px;
			a{
				display: inline-block;
				position: relative;
				width: 25px;
				height: 25px;
				@include lazylinks(transparent);
				&:before{
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					background-size: contain;
				}
			}
		}
	}
	.comment{
		@include flexbox;
		a{
			margin-left: 12px;
			padding-top: 3px;
			text-decoration: underline;
		}
	}
	.ctn-info{
		@include flexbox;
		@include flex-wrap(wrap);
		margin: 20px 0 5px 0;
		padding: 20px 0;
		border-top: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
		color: $dark-grey;
		.left{
			width: 100%;
			span{
				display: inline-block;
				margin-bottom: 3px;
				font-weight: bold;
			}
			ul{
				padding-left: 15px;
				list-style-type: disc;
				ul{
					li{
						width: 100%;
					}
				}
			}
			li{
				float: left;
				width: 50%;
			}
		}
		.right{
			width: 100%;
			margin-top: 20px;
			>div{
				position: relative;
				margin-bottom: 6px;
				padding-left: 25px;
			}
			svg{
				position: absolute;
				max-height: 22px;
				max-width: 22px;
				left: 0px;
			}
		}
		.reservation{
			display: inline-block;
			margin-top: 8px;
			padding: 10px 30px;
			@include fontsize(18);
			text-transform: uppercase;
			@include lazylinks(white);
			font-family: $font-title;
		}
	}

	@include bp(min, $tablet){
		//Si page détail article marge sur conteneur aside
		&+.region-asideright{
			margin-top: 70px;
		}


		.btn-back{
			top: 45px;
			@include fontsize(15);
			&:before{
				top: -2px;
			}
		}
		.social{
			ul{
				margin: 0 20px 0 16px;
				padding-right: 10px;
			}
			span{
				@include fontsize(18);
			}
		}
		.ctn-info{
			padding: 20px;
			.left{
				width: 70%;
			}
			.right{
				margin-top: 0;
				width: 30%;
			}
		}
	}
}


/* nav evenement */
.nav-evt{
	width: 100%;
	margin-top: 45px;
	border-bottom: 1px solid $light-grey;
	ul{
		@include flexbox;
		@include flex-wrap(wrap);
	}
	li{
		border: 1px solid $light-grey;
		border-bottom: none;
		border-right: none;
		&:last-child{
			border-right: 1px solid $light-grey;
		}
	}
	a{
		display: inline-block;
		padding: 7px 16px;
		font-family: $font-title;
		color: $dark-grey;
		text-transform: uppercase;
		@include transition(all 0.3s ease);
		&:hover{
			color: white;
		}
	}
}

//Filtre liste catagories
.views-exposed-form{
	width: 100%;
	margin-top: 20px;
	border-bottom: 1px solid $light-grey;
	.views-exposed-widgets{
		margin-bottom: 0;
	}
	input,
	select{
		width: 100%;
		padding: 7px 48px 7px 16px;
		border: 1px solid $light-grey;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $font-rob-reg;
	}
	label{
		display: none;
	}
	.views-exposed-widget{
		width: 100%;
		padding-right: 0;
	}
	.form-type-select{
		position: relative;
		&:before{
			content: "";
			position: absolute;
			right: 15px;
			top: 12px;
			width: 9px;
			height: 9px;
			background: url(../img/picto/picto_triangle.svg);
			background-size: cover;
			@include transform(rotate(90deg));
		}
		&:after{
			content: "";
			position: absolute;
			right: 36px;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: $grey;
			z-index: 10;
		}
	}
	@include bp(min, $tablet){
		input,
		select{
			width: auto;
			border-bottom: none;
		}
		.views-exposed-widget{
			width: auto;
			padding-right: 20px;
		}
	}
}


/* 2eme partie evenement */
#interview{
	margin: 40px 0 30px 0;
	padding-bottom: 30px;
	border-bottom: 1px solid $light-grey;
	.ctn-2col{
		@include flexbox;
		@include flex-wrap(wrap);
	}
	.ctn-img{
		width: 340px;
	}
	.sub-title{
		@include fontsize(20);
		font-weight: bold;
		margin: 30px 0 15px 0;
	}
	.citation{
		@include fontsize(18);
		font-weight: bold;
	}
	@include bp(min,$tablet){
		padding-bottom: 40px;
		.h2{
			margin: 0 0 8px 12px;
		}
		.right{
			@include calc(width, '100% - 340px ');
			padding-left: 30px;
		}

	}
}

#video{
	width: 100%;
	iframe{
		width: 100%;
	}
	@include bp(max,767px){
		iframe{
			height: 70vh;
		}
	}
}
.spectacle{
	margin-top: 50px;
	#map{
		max-width: 100%;
	}
	@include bp(min,$tablet){
		#map{
			width: 100%;
		}
	}
}

.facebook:before{
	background: url(../img/picto/picto_facebook.svg) no-repeat center;
}
.twitter:before{
	background: url(../img/picto/picto_twitter.svg) no-repeat center;
}



//Recherche agenda commun
#-wik-agenda-form,
#-wik-agenda-form--2{
	position: relative;
	background-color: white;
	.form-item{
		margin-top: 0;
		margin-bottom: 0;
		position: relative;
		background-color: transparent;
		&:after{
			content: "";
			position: absolute;
			right: 36px;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: $grey;
			z-index: 10;
		}
	}

	input,
	select{
		padding: 7px 16px;
		border: 1px solid $light-grey;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		cursor: pointer;
		@include input-placeholder{
			color: $dark-grey;
		}
	}

	//Picto input
	.form-type-select,
	.form-item-keywords,
	.form-item-date,
	.form-item-place{
		&:before{
			content: "";
			position: absolute;
		}
	}
	//Picto svg select
	.form-type-select{
		&:before{
			right: 15px;
			top: 12px;
			width: 9px;
			height: 9px;
			background: url(../img/picto/picto_triangle.svg);
			background-size: cover;
			@include transform(rotate(90deg));
		}
	}
	//Picto mot clé, date et lieux
	.form-item-keywords,
	.form-item-date,
	.form-item-place{
		&:before{
			right: 11px;
			top: 7px;
			width: 15px;
			height: 15px;
		}
	}
	//Champ mot-clés
	.form-item-keywords{
		&:before{
			background: url(../img/svg/picto_cle_grey.svg);
			background-size: cover;
		}
	}
	//Champ date
	.form-item-date{
		&:before{
			background: url(../img/svg/picto_calendar_grey.svg);
			background-size: cover;
		}
	}
	//Champ lieux
	.form-item-place{
		&:before{
			background: url(../img/svg/picto_lieu_grey.svg);
			background-size: cover;
		}
	}
	//Container btn recherche
	.form-item--submit{
		position: relative;
		&:after{
			display: none;
		}
		&:before{
			content: "";
			position: absolute;
			bottom: 11px;
			left: 18px;
			display: inline-block;
			width: 10px;
			height: 10px;
			background: url(../img/picto/picto_triangle_white.svg);
			background-size: cover;
		}
	}
	//input btn recherche
	.form-submit{
		@include upperColor(white, 15);
		font-weight: 700;
		cursor: pointer;
		@include transition(all 0.3s ease);
	}
}

//Page agenda
.agenda{
	.wrap900{
		margin-left: 0;
	}
	.view-publicites{
		padding: 40px 0 50px 0;
	}
	@include bp($tablet, 1200px){
		.wrap900{
			margin: 0 auto;
		}
	}

}
//Agenda wik page agenda
.agenda-wik{
	margin: 0 auto;
	width: 100%;
	max-width: 900px;
	.red{
		color: $red-select;
	}
	h1{
		padding: 45px 0 20px 20px;
		background-color: white;
	}
	form{
		background: url(../img/bg/bg_stripes.png);
		>div{
			padding: 20px 12px 20px 12px;
		}
	}
	.form-item{
		margin-bottom: 20px;
		position: relative;
		input,
		select{
			width: 100%;
		}
	}
	.form-item,
	.form-submit{
		width: 90%;
		margin: 0 auto;
	}

	//Btn recherche
	.extra_div{
		input{
			border: 1px solid $red-select!important;
		}
		.form-submit{
			background-color: $red-select;
			@include upperColor(white,15);
		}
	}
	@include bp(min, $tablet){
		margin: 0;
		form{
			>div{
				@include flexbox;
				@include flex-wrap(wrap);
				@include justify-content(space-between);
				padding: 40px 60px;
			}
		}
		.form-item,
		.extra_div{
			width: 31%;
			.form-submit{
				width: 100%;
			}
		}
		#-wik-agenda-form{
			.form-item,
			.form-item--submit{
				margin: 0 0 25px 0;
			}
		}
	}
	@include bp(max, $tablet){
		#-wik-agenda-form{
			.form-submit{
				display: block;
				margin: 0 auto;
			}
		}
	}
}

.ctn-result-recherche{
	background-color: white;
	padding: 10px;
	.title{
		@include fontsize(30);
		padding-left: 10px;
		.red{
			color: $red-select;
		}
	}
	.swiper-container{
		margin-bottom: 60px;
		padding: 35px 20px 0 20px;
		border-top: 1px solid $grey;
	}
	.ctn-wrap{
		height: 250px;
		background-color: $light-grey;
	}
	@include bp(min, $tablet){
		.title{
			@include fontsize(34);
		}
	}
}

//Btn swipper
.swiper-button-next,
.swiper-button-prev{
	margin-top: 0;
	height: 20px;
}
.swiper-button-prev{
	left: 0;
}
.swiper-button-next{
	right: 0;
}


/*		Aside 		*/
.region-asideright{
	width: 100%;
	padding: 10px 12px;
	color: $dark-grey;

	margin-bottom: 30px;

	//Bloc recherche agenda
	#-wik-agenda-form,
	#-wik-agenda-form--2{
		margin-bottom: 30px;
		.form-item{
			margin: 0 auto;
		}
		input,
		select{
			width: 100%;
			border-top: none;
		}
		#edit-category,
		#edit-category--2{
			border-top: 1px solid $light-grey;
		}
		.form-item--submit{
			&:before{
				bottom: 16px;
			}
		}
		.form-submit{
			padding: 12px 40px;
			border: 1px solid $orange;
			background-color: $orange;
			/*&:hover{
				background-color: white;
				color: $orange;
			}*/
		}
	}

	.view-publicites{
		background-color: transparent;
	}



	#edit-place{
		border-bottom: none;
	}
/*	.reclamation{
		width: 250px;
		height: 250px;
		background: url(../img/bg/img_bg_pub.png);
	}*/
/*	.bloc-recherche{
		height: 160px;
		border: 1px solid $light-grey;
		margin: 20px 0 40px 0;
	}*/
/*	.vue-soon{
		.title{
			display: block;
			margin-bottom: 5px;
			padding: 15px 0 15px 10px;
			background-color: white;
			color: black;
			@include fontsize(26);
		}
	}*/
	@include bp(min, $tablet){
		@include flexbox;
		@include flex-wrap(wrap);
		@include justify-content(space-evenly);
	}
	@include bp($tablet, 1200px){
		.view-publicites{
			padding: 10px 0;
			margin: 0 auto;
		}
	}
	@include bp(min, 1200px){
		width: 250px;
		display: block;
		padding: 0;
		#search-wik{
			width: 100%;
			margin-top: 25px;
			.title{
				background-color: white;
			}
		}
		.bloc-recherche{
			width: 100%;
			height: 160px;
			border: 1px solid $light-grey;
			margin: 20px 0 40px 0;
		}
	}
}

//recherche sidebar
#block-wik-sidebar-search{
	.title{
		background-color: white;
		font-family : $font-rob-reg;
		@include fontsize(24);
		padding: 15px 0 15px 16px;
		margin-bottom: 16px;
		.red{
			color: $orange;
		}
	}
}


//Aside vous aimerez
#block-views-vous-aimerez-aussi-block,
#block-views-vous-aimerez-aussi-block-2,
#block-views-vous-aimerez-aussi-block-3,
#block-views-vous-aimerez-aussi-block-4,
#block-views-vous-aimerez-aussi-block-5,
#block-views-vous-aimerez-aussi-block-6,
#block-views-vous-aimerez-aussi-block-7,
#block-views-bientot-dans-ce-lieu-block,
#block-views-vous-aimerez-aussi-block-1,
#block-views-accueil-scene-block-1{
	margin-top: 35px;
	color: $dark-grey;
	padding: 15px 10px 1px 10px;
	background-color: white;
	h2{
		margin-bottom: 10px;
	}
	.views-row{
		position: relative;
		margin-bottom: 15px;
		&:hover{
			.views-field-title{
				background-color: $red-select;
				a{
					color: white;
				}
			}
		}
	}
	//Titre
	.views-field-title{
		position: absolute;
    	bottom: 0;
    	width: 100%;
    	padding: 10px;
		border-left: 6px solid $red-select;
    	background-color: rgba(255,255,255,.88);
		font-weight: 700;
		@include transition(all 0.3s ease);
		cursor: pointer;
	}
}

//Aside blocs jeu, newsletter, appli
#block-wikmotion-m-wikmotion-m-triple-block-sidebar{
	#bloc-appli,
	#block-newsletter,
	#block-jeux{
		margin-top: 30px;
	}

	#bloc-appli{
		text-align: center;
		border: 1px solid $light-grey;
		background-color: white;
		.description{
			&:before{
				left: 0px;
				top: -3px;
			}
		}
		.android,
		.appstore{
			margin: 10px auto;
		}
	}
	#block-newsletter{
		text-align: center;
	}
	.form-text{
		padding: 5px;
		margin: 0;
	}
	.form-item-mail{
		height: 30px;
		width: calc(100% - 39px);
	}
	#block-jeux{
		height: 220px;
		.title{
			padding-left: 36px;
			margin-bottom: 24px;
		}
		.link{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 12px 0 12px 42px;
			&:before{
				top: 15px;
    			left: 20px;
			}
		}
		.description{
			position: relative;
			width: 100%;
		}
	}
	@include bp($tablet, 1200px){
		width: 100%;
		.content{
			@include flexbox;
			@include justify-content(space-between);
			>div{
				width: 30%;
			}
		}
	}
}
.logged-in{
	.inscription{
		.mail{
			display: block;
			text-align: center;
		}
	}
	#block-newsletter .simplenews-unsubscribe,
	#block-newsletter .simplenews-subscribe {
		.form-submit {
			font-family: $font-family;
			cursor: pointer;
		    width: 100%;
		    background-color: $red-select;
		    border: none;
		    color: #fff;
		    text-transform: uppercase;
		    padding: 5px;
		    margin-top: 5px;

		    position: relative;
		}
	}
}




.listing-articles,
.vue-liste-categories,
.vue-details-evenements{
	color: $dark-grey;
	padding: 0 20px;
	background-color: white;
	@include bp(min, $tablet){
		max-width: none;
		@include calc( width, '100% - 280px' );
		margin-right: 30px;
	}
	.article{
		width: 100%;
		padding: 32px 0;
		border-bottom: 1px solid $light-grey;
		margin-bottom: 30px;
		&.aqua{
			.ctn-title{ border-left: 6px solid $aqua; }
			.categ{ color: $aqua; }
			.btn-link{ @include btn-link-bg($aqua); }
		}
		&.blue{
			.ctn-title{ border-left: 6px solid $blue; }
			.categ{ color: $blue; }
			.btn-link{ @include btn-link-bg($blue); }
		}
		&.gold{
			.ctn-title{ border-left: 6px solid $gold; }
			.categ{ color: $gold; }
			.btn-link{ @include btn-link-bg($gold); }
		}
		&.green{
			.ctn-title{ border-left: 6px solid $green; }
			.categ{ color: $green; }
			.btn-link{ @include btn-link-bg($green); }
		}
		&.light-blue{
			.ctn-title{ border-left: 6px solid $light-blue; }
			.categ{ color: $light-blue; }
			.btn-link{ @include btn-link-bg($light-blue); }
		}
		&.orange{
			.ctn-title{ border-left: 6px solid $orange; }
			.categ{ color: $orange; }
			.btn-link{ @include btn-link-bg($orange); }
		}
		&.purple,
		&.violet{
			.ctn-title{ border-left: 6px solid $purple; }
			.categ{ color: $purple; }
			.btn-link{ @include btn-link-bg($purple); }
		}
		&.yellow{
			.ctn-title{ border-left: 6px solid $yellow; }
			.categ{ color: $yellow; }
			.btn-link{ @include btn-link-bg($yellow); }
		}
		@include transition(all 0.3s ease);
		&:hover{
			background-color: #FFF;
		}
	}
	&.red,
	&.rouge{
		.ctn-title{ border-left: 6px solid $red-select; }
		.categ{ color: $red-select; }
		.btn-link{ @include btn-link-bg($red-select); }
	}
	.ctn-img{
		width: 100%;
		margin-bottom: 10px;
		img{
			width: 100%;
		}
	}
	.title{
		@include fontsize(32);
	}
	.ctn-title{
		@include fontsize(20);
		margin-bottom: 18px;
		.h2{
			margin-left: 6px;
			font-weight: bold;
			@include fontsize(20);
		}
	}
	.categ{
		margin-left: 6px;
	}
	.btn-link{
		padding: 5px 10px;
		margin: 12px 0;
		@include lazylinks(white);
		@include fontsize(14);
		@include btn-link-bg($red-select);
	}
	.area,
	.date{
		position: relative;
		margin-bottom: 12px;
		padding-left: 25px;
		b{
			display: block;
		}
		@include bp(min,$tablet){
			b{
				display: inline-block
			}
		}
	}
	.date{
		b{
			margin-right: 10px;
		}
		@include bp(min,$tablet){
			@include flexbox;
		}
	}
	svg{
		position: absolute;
    	max-height: 22px;
    	max-width: 22px;
    	/*left: 16px;*/
    	left: 0px;
		top: -2px;
	}
	@include bp(min, $tablet){
		@include wrap900;
		margin: 0;
		.article{
			@include flexbox;
			@include justify-content(space-around);
			@include flex-wrap(wrap);
		}
		.ctn-img{
			width: 280px;
			margin-bottom: 0;
		}
		.ctn-right{
			@include calc( width, '100% - 280px');
			padding-left: 20px;
		}
		.ctn-title{
			margin-bottom: 25px;
		}
	}
}

.area{
	cursor: pointer;
}

//Page log-in
.page-user{
	#header{
		background-color: white;
	}

	.breadcrumb{
		position: static;
	}
}

.breadcrumb{
	z-index: 10;
}

//Block pub
.view-publicites{
	background-color: white;
	max-width: 900px;
	margin: 20px auto;
	img{
		display: block;
		margin: 0 auto;
	}
}

//Pagination bottom pages
.pagination--wrapper{
	padding-bottom: 20px;
}


//Page de résultats d'une recherche
.page-search-node{
	//Formulaire de recherche
	.search-form{
		width: 100%;
		max-width: 900px;
		padding: 40px 20px 30px 20px;
		background-color: white;
	}
	.red{
		color: $red-select;
	}
	.region-content{
		.form-wrapper{
			input{
				width: 100%;
				padding: 7px 16px;
				border: 1px solid $light-grey;
			}
			label{
				display: inline-block;
				margin-right: 20px;
				margin-bottom: 10px;
			}
			.form-item{
				margin: 0;
			}
			.form-submit{
				background-color: $red-select;
				border: 1px solid $red-select;
				@include upperColor(white, 14);
				font-weight: 700;
			}

		}
	}
	@include bp(min,$tablet){
		.region-content{
			.form-wrapper{
				@include flexbox;
				label{
					margin-bottom: 0;
				}
				input{
					width: auto;
				}
			}
		}
	}
	//Résultats
	.wrapper-search-result{
		width: 100%;
		max-width: 900px;
		h2{
			margin-top: 20px;
			padding: 15px 0 15px 15px;
			background-color: white;
		}
	}
	.search-results{
		padding: 0;
		margin: 0;
		li{
			margin : 0 0 10px 0;
			padding: 25px 20px 25px 30px;
			background-color: white;
		}
	}
	.ctn-title{
		padding-left: 10px;
		margin-bottom: 15px;
	}
	.title{
		@include fontsize(20);
	}

	//Pagination
	.item-list{
		.pager{
			margin: 20px 0;
			padding: 15px 0;
			background-color: white;
		}
		.pager-current{
			color: white;
			background-color: $red-select;
		}
		ul li{
			display: inline-block;
			margin: 0 0 10px 5px;
			padding: 6px 10px;
			border: 1px solid $light-grey;
		}
		.pager-first,
		.pager-previous,
		.pager-next,
		.pager-last,
		.pager-ellipsis{
			border: none;
		}
	}
	.element-invisible{
		display: none;
	}
	@include bp(min, $tablet){
		.item-list{
			ul li{
				margin: 0 0 0.0 5px;
			}
		}
	}
}


//Pagination
.item-list{
	.pager{
		margin: 20px 0;
		padding: 15px 0;
		background-color: white;
		li{
			display: inline-block;
			margin: 0 0 10px 5px;
			padding: 6px 10px;
			border: 1px solid $light-grey;
		}
		.pager-current{
			color: white;
			background-color: $red-select;
		}
		.pager-first,
		.pager-previous,
		.pager-next,
		.pager-last,
		.pager-ellipsis{
			border: none;
		}
	}
}


//Slider listing produits (vous aimerez aussi)
.sliderListingProduits{
	border-bottom: 1px solid $lighty-grey;
	padding-bottom: 30px;
	margin-bottom: 30px;
	.content-slide{
		margin-top: 30px;
		@include flexbox;
		@include flex-direction(column-reverse);
	}
	span.field-content{
		border-bottom: 20px;
		padding-left: 12px;
		border-left: 6px solid $blue;
		color: $dark-grey;
		font-family: $font-rob-reg;
		font-weight: 400;
		@include fontsize(25);
		@include lineheight(23);
	}
	.swiper-pagination-bullets{
		bottom: 180px;
	}

	@include bp(min, 769px){
		span.field-content{
			margin: 0 0 30px 0;
			@include fontsize(38);
			line-height: normal;
		}
	}
}

//Page jeux
#block-views-jeux-jeux-en-cours,
#block-views-jeux-jeux-a-venir{
	h2{
		background-color: white;
		padding: 0px 20px 0 20px;
		text-transform: capitalize;
	}
}
.jeux-entete{
	background-color: white;
	padding: 20px 0 20px 20px;
	h2{
		border-bottom: 20px;
		padding-left: 12px;
		border-left: 6px solid $red-select;
		color: $dark-grey;
		font-family: $font-rob-reg;
		font-weight: 400;
		@include fontsize(25);
		@include lineheight(23);
	}
	@include bp(min, 769px){
		h2{
			margin: 0 0 30px 0;
			@include fontsize(38);
			line-height: normal;
		}
	}
}

.jeux_en_cours,
.jeux_a_venir{
	.date{
		position: relative;
		padding-left: 30px;
	}
	svg{
		top: -4px;
	}
	.ctn-right{
		a{
			color: $red-select;
			border: 1px solid $red-select;
		}
	}
}

//Page de connexion
#user-login{
	@include wrap900;
	max-height: 300px;
	margin-left: 0;
	padding: 20px 0 0 20px;
	background-color: white;
	>div{
		width: 96%;
		max-width: 500px;
		margin: 30px auto;
	}
	label{
		display: inline-block;
		margin-bottom: 12px;
	}
}

//Liste selectin wik
.not-front{
	.view-selection-de-wik{
		.views-row{
			margin-bottom: 30px;
		}
		@include bp(min, $tablet){
			@include wrap900;
			margin: 0;
			.ctn-wrap{
				@include flexbox;
				@include justify-content(space-around);
				@include flex-wrap(wrap);
				width: 100%;
				a{
					display: inline-block;
					width: 280px;
					margin-bottom: 0;
				}
			}
			.discover{
				position: static;
				@include calc( width, '100% - 280px');
				padding-left: 20px;
				&:hover {
					.title{
						border-left: 6px solid $red-select;
					}
					.ctn{
						bottom: auto;
					}
				}
			}
			.ctn{
				position: static;
				opacity: 1;
				span{
					padding: 0;
				}
				a{
					width: auto;
				}
			}
			.title{
				margin-bottom: 25px;
			}
		}
	}

}

//Page création de compte
.page-creation-compte{
	.region-asideright{
		display: none;
	}

	#wik-user-register-form{
		width: 100%;
    	max-width: 1200px;
    	padding: 20px;
		background-color: white;

		label{
			display: inline-block;
			margin-bottom: 4px;
		}
		select,
		input{
			width: 100%;
			padding: 5px 5px 5px 8px;
		}
		.form-type-checkbox{
			@include flexbox;
			input{
				width: auto;
				margin-right: 10px;
			}
		}
		//Taille formulaire
		.form-wrapper{
			width: 100%;
			max-width: 300px;
			border: none;
		}
		legend{
			@include fontsize(20);
			padding-left: 18px;
			border-left: 6px solid $red-select;
		}

		//Titre form
		#edit-titre{
			width: 100%;
			@include fontsize(20);
		}

		//Abo newsletter
		.form-item-newsletter-partenaire{
			margin-top: 15px;
			font-weight: bold;
		}
		.form-managed-file{
			.form-submit{
				display: inline-block;
				padding: 10px 19px;
				border: 1px solid $grey;
				@include fontsize(13);
				font-family: $font-rob-reg;
				color: white;
				background-color: $grey;
				text-transform: uppercase;
				cursor: pointer;
			}
		}
		//btn submit
		#edit-submit{
			display: inline-block;
			padding: 10px 19px;
			border: 1px solid $red-select;
			@include fontsize(13);
			font-family: $font-rob-reg;
			color: white;
			background-color: $red-select;
			text-transform: uppercase;
			cursor: pointer;
		}
	}

	@include bp(min, $tablet){
		#wik-user-register-form{
			>div{
				@include flexbox;
				@include flex-wrap(wrap);
				@include justify-content(space-around);
			}
			#edit-titre{
				@include fontsize(30);
			}
			.form-wrapper{
				max-width: 350px;
			}
		}
	}
	@include bp(min, $normal){
		#wik-user-register-form{
			#edit-titre{
				@include fontsize(30);
			}
			.form-wrapper{
				max-width: 400px;
			}
		}
	}
}

//Page de profil membre
.page-membre{
	.region-content{
    	width: 100%;
    	max-width: 900px;
    	padding: 20px;
		background-color: white;
		>div{
			margin-bottom: 30px;
			>div{
				margin-bottom: 20px;
			}
		}
	}
	.titre{
		display: block;
		margin: 0 0 12px 0px;
		padding-left: 12px;
		border-left: 6px solid $red-select;
		color: $dark-grey;
		font-family: $font-rob-reg;
		font-weight: 400;
		@include upperColor($dark-grey, 22);
		@include lineheight(23);

	}
	h2{
		font-weight: normal;
		margin-bottom: 5px;
		@include fontsize(18);
		color: $blue;
	}
	.block-views {
		 padding-left: 18px;
	}
	//Infos profil
	.view-compte-profil-membre{
		>a{
			text-decoration: underline;
			font-weight: bold;
			@include fontsize(15);
		}
		.views-row{
			>div{
				margin-bottom: 12px;
			}
		}
		.views-field-field-membre-photo{
			margin-right: 30px;
		}
		.views-field-field-membre-photo{
			width: 280px;
		}
		span{
			font-weight: bold;
		}
		.views-field-field-membre-date-naissance{
			display: flex;
			&:before{
				display: inline-block;
				content: "Age : ";
				font-weight: 700;
			}
		}
	}

	@include bp(min, 769px){
		.titre{
			@include fontsize(30);
			line-height: normal;
		}
		h2{
			@include fontsize(22);
		}
		.view-compte-profil-membre{
			.views-field{
				float: left;
				@include calc(width, '100% - 330px');
			}
			.views-field-field-membre-photo{
				width: 300px;
			}
		}
	}
}


//Page myWik
.profile{
	width: 100%;
	padding: 20px 12px;
	background-color: white;
	h3{
		@include upperColor(25);
		padding-left: 16px;
		border-left: 6px solid $red-select;
	}
	img{
		display: inline-block;
		padding: 20px;
	}
	.content{
		.field{
			margin-bottom: 12px;
		}
	}
	@include bp(min,$tablet){
		padding: 20px 0 0 20px;
		margin-left: 0;
		@include wrap900;
	}
}
.profile2-profil-membre{
	background-color: white;
	.field{
	}
	.field-name-field-membre-photo{
		width: 300px;
		margin: 0 auto;
	}
	@include bp(min,$tablet){
		.field{
			float: left;
			@include calc(width, '100% - 330px');
		}
		.field-name-field-membre-photo{
			margin: 0 30px 0 0;
			img{
				padding-bottom: 45px;
			}
		}
	}
}



//Commetaires
form.comment-form{
	position: relative;
	margin-bottom: 50px;
	label{
		margin-bottom: 8px;
	}
	textarea{
		background-color: $lighty-grey;
		width: 100%;
		height: 200px;
		padding: 20px;
		resize: none;
		border: none;
	}
	.grippie{
		display: none;
	}
	.connexion{
		display: inline-block;
		margin-bottom: 10px;
		font-weight: bold;
		@include lazylinks($red-select);
		text-decoration: underline;
		text-transform: uppercase;
	}
	.filter-wrapper{
		display: none;
	}
	>div{
		@include flexbox;
		@include flex-wrap(wrap);
		@include justify-content(space-between);
	}
	.form-item-subject{
		@include calc(width, '100% - 100px');
	}
	.form-item-note{
		width: 60px;
	}
	.field-widget-text-textarea{
		width: 100%;
	}
	input{
		padding-left: 10px;
	}
	input,
	select{
		width: 100%;
		background-color: $lighty-grey;
		border: none;
		height: 38px;
	}
	#edit-actions{
		position: absolute;
		bottom: 20px;
		right: 0px;
		margin: 0;
	}
	#edit-submit{
		color: white;
		text-transform: uppercase;
		font-weight: 600;
		background-color: $red-select;
		border: 1px solid $red-select;
		cursor: pointer;
	}
	//masque un titre totalement inintéressant
	&+.title{
		display: none;
	}
}

//Liste commentaires
.comment-list{
	margin-top: 30px;
	.infos{
		margin-bottom: 20px;
		b{
			font-weight: bold;
			@include fontsize(20);
		}
	}
	li{
		margin-bottom: 35px;
		padding-bottom: 35px;
		border-bottom: 1px solid $light-grey;
	}
}

.wrapper_commentaires{
	padding-bottom: 20px;
	margin-bottom: 30px;
}

#comments{
	padding-left: 15px;
	form{
		margin-bottom: 0;
	}
	.comment_title{
		@include fontsize(16);
		font-weight: bold;
		color: $red-select;
		text-transform: capitalize;
	}
	.comment{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
   		border-bottom: 1px solid #ddd;
    	padding-bottom: 15px;
	}
	.submitted{
		display: flex;
		margin: 0 0 10px 0;
	}
	a{
		margin-left: 0;
	}
	.auteur{
		@include fontsize(17);
		font-weight: bold;
		text-transform: capitalize;
	}
	.username{
		@include fontsize(20);
		@include lazylinks($black);
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 600;
	}

	.links.inline{
		display: block;
		margin: 10px 0 20px 0;
		li{
			padding: 0 20px 0 0;
		}
	}
	//Etoiles notes
	.user-note{
		display: flex;
		margin-left: 18px;
		img{
			width: 16px;
			height: 16px;
		}
	}

	//Commentaire
	.content{
		display: block;
		width: 100%;
	}
}

//Map
.ctn-map,
#map{
	max-width: 100vw;
}

.media-youtube-video,
.media-dailymotion-outer-wrapper {
	padding-top: 58%;

	iframe {
		position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    height: 100%;
	    width: 100%;
	}
}


//Page my wik
.page-sites-mywik, .page-utilisateur-mywik{

	@include bp(min, $tablet){
		.vue-rencontres-home{
			.view-content{
				flex-wrap: wrap;
				justify-content: flex-start;
			}
			.views-row{
				margin: 10px 1%;
				&.views-row-first{
					width: 31%;
					height: auto;
				}
			}
		}
	}
}

//Interview
.interview_chapeau{
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
	font-style: italic;
	color: #303030;
}


//Evenements similaires
.vue-details-evenements{
	padding: 20px;
	.views-row{
		display: flex;
		margin-bottom: 20px;
	}
	//Image
	.views-field-field-evt-affiche{
		width: 150px;
		margin-right: 20px;
	}
	img{
		width: 100%;
		height: auto;
	}
	//Contenu, partie droite
	.contenu-selection{
		width: calc(100% - 170px);
	}
	.ctn-title{ border-left: 6px solid $red-select; }
	.categ{ color: $red-select; }
}

//Bandeau dans le cadre de evenement
.view-1-front-page-details-evenement{
	background-color: #ffffff;
    padding: 10px 20px 10px 20px;
   /* border: 1px solid #e2e2e2;*/
	.cadre{
		@include fontsize(16);
	}
	.field-content{
		display: flex;
		align-items: flex-start;
	}
	.lien-evt{
		margin: 0 20px;
		color: #de2726;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 18px;
	}
	.btn-link{
		@include fontsize(12);
		text-transform: uppercase;
		font-weight: bold;
		@include lazylinks(white);
	}
}