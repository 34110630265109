@font-face {
	font-family: "iconfont";
	src: url('../iconfont/iconfont.eot');
	src: url('../iconfont/iconfont.eot?#iefix') format('eot'),
		url('../iconfont/iconfont.woff2') format('woff2'),
		url('../iconfont/iconfont.woff') format('woff'),
		url('../iconfont/iconfont.ttf') format('truetype'),
		url('../iconfont/iconfont.svg#iconfont') format('svg');
}

.icon:before {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-eye-close-up:before {
	content: "\E001";
}

.icon-search:before {
	content: "\E002";
}

.icon-uEA01-search:before {
	content: "\E003";
}

.icon-up-arrow:before {
	content: "\E004";
}
