a{
   text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
}

img{
   display: block;
}
/*** FOCUS OUTLINE RESET *************************/
input:focus,
select:focus,
a:focus { outline: 0;}


/*** IOS FORM RESET ******************************/
/*
input, textarea, select { -webkit-appearance: none;  }
*/
