/*-----------------COLOR------------------------*/

$blanc: #ffffff;
$black: #303030;

$lighty-grey: #f2f2f2;
$light-grey: #c8c8c8;
$grey-40: #404040;
$grey-99: #999999;
$grey: #d6d4d4;
$dark-grey: #2d2d2d;

$blue-grey: #6b757d;
$light-white: #fdfdfd;
$red: #c33f58;
$red-select: #de2726;

$aqua:  #008577;
$blue: #2a5264;
$light-blue: #59acd8;
$green: #99c46f;
$gold: #b2894f;
$orange: #e85f4a;
$purple: #911844;
$yellow: #deaf30;

/*** TRANSITION ***********************************/

$transition-duration: .3s;
$transition-function: ease-in-out;
$transition-property: all;
$transition-base: $transition-duration $transition-function $transition-property;




/*** FONTS *************************************************************************************/

/*@font-face {
	font-family: 'roboto_condensed';
	src: url('../font/robotocondensed-regular.woff2') format('woff2'),
	url('../font/robotocondensed-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}*/
@font-face {
	font-family: 'roboto_condensed';
	src: url('../font/robotocondensed-bold.woff2') format('woff2'),
	url('../font/robotocondensed-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'open_sans';
	src: url('../font/opensans-regular-webfont.woff2') format('woff2'),
	url('../font/opensans-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'open_sans';
	src: url('../font/opensans-bold-webfont.woff2') format('woff2'),
	url('../font/opensans-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'roboto_regular';
	src: url('../font/roboto-Regular.woff2') format('woff2'),
	url('../font/roboto-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/*** DEFAULT *************************************/

$font-title: 'roboto_condensed', Arial, sans-serif;
$font-primary: 'open_sans', Arial, Helvetica, sans-serif;
$font-family: 'open_sans', Arial, Helvetica, sans-serif;
$font-rob-reg: 'roboto_regular', Arial, Helvetica, sans-serif;