#footer{
	padding: 40px 0;
	background-color: $dark-grey;
	color: $light-grey;
	a{
		@include lazylinks($light-grey);
	}
	.h3{
		font-family: $font-title;
		margin-bottom: 20px;
	}
}


#block-block-11{
	text-align: center;
	padding: 0 12px 30px 12px;
	border-bottom: 1px solid $grey-40;
	@include fontsize(13);
	@include lineheight(15);
	@include bp(min, $tablet){
		padding: 0 0 50px 20px;
	}
}

.region-footer{
	width: 100%;
	@include flexbox;
	@include justify-content(space-between);
	@include flex-wrap(wrap);
	padding: 24px 0;
	border-bottom: 1px solid $grey-40;
	.title{
		display: inline-block;
		margin-bottom: 12px;
		@include fontsize(15);
		font-family: $font-title;
		text-transform: uppercase;
		text-decoration: underline;
	}
	@include bp(min, $tablet){
		padding: 34px 0;
	}
}
#block-menu-menu-menu-footer-milieu{
	width: 200px;
	margin: 20px 0;
	.collapsed{
		a{
			@include fontsize(13);
		}
	}
	@include bp(min, $tablet){
		margin: 0;
	}
}

//Footer top, liens principaux menu footer (Resto, bars, etc ...)
#block-wikmotion-wikmotion-footermenu{
	width: 100%;
}
#block-wikmotion-m-wikmotion-m-footermenu{
	width: 100%;
}
#block-menu-menu-footer-top{
	width: 100%;
	padding: 30px 10px 0px 10px;
	margin-bottom: 30px;
	@include flexbox;
	@include flex-wrap(wrap);
	@include justify-content(space-between);
	border-bottom: 1px solid $grey-40;
	.content{
		width: 100%;
		>.menu{
			width: 100%;
			@include flexbox;
			@include justify-content(space-between);
			@include flex-wrap(wrap);
		}
	}
	.menu {
		ul{
			li{
				@include fontsize(12);
				@include lineheight(17);
				font-family: $font-primary;
				&:first-child{
					/*@include fontsize(15);
					text-transform: uppercase;*/
				}
			}
		}
	}
	//Level 0 menu
	.expanded{
		>a{
			display: inline-block;
			margin-bottom: 12px;
			@include upperColor($grey, 15);
			text-align: left;
			text-decoration: underline;
			font-family: $font-title;
		}
	}

	@include bp($tablet, $normal){
		.level-0{
			width: 25%;
			text-align: center;
		}
	}
	@include bp(min, $normal){
		padding: 30px 10px 5px 10px;
	}
}

//Newsletter (visible en entier hors ligne)
#block-simplenews-588{
	width: 220px;
	h2{
		margin-bottom: 15px;
		font-family: $font-title;
		text-decoration: underline;
		text-transform: uppercase;
		@include fontsize(15);
	}
	form>div{
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		&:before{
			content: "";
			position: absolute;
			right: 9px;
			top: 8px;
			display: inline-block;
			width: 10px;
			height: 10px;
			background: url(../img/picto/picto_triangle.svg);
			background-size: cover;
			cursor: pointer;
		}
	}
	.form-item{
		margin: 0;
	}
	.form-type-checkboxes{
		margin-top: 6px;
	}
	//Mail
	.form-item-mail{
		margin: 0;
		@include calc(width, '100% - 30px');
		label{
			display: none;
		}
		input{
			width: 100%;
			padding: 6px;
			border: none;
		}
	}

	input{
		padding: 6px;
		background-color: $grey;
		border: none;
	}
	.form-submit{
		width: 28px;
		border: none;
		border-left: 1px solid $dark-grey;
		color: transparent;
		&:hover{
			cursor: pointer;
		}
	}
}

.logged-in {
	#block-simplenews-588{
		.simplenews-unsubscribe,
		.simplenews-subscribe {
			.form-submit {
				width: 100%;
				color: initial;
			}
		}
	}
}


.footer-middle{
	.appli-mag{
		@include flexbox;
	}
}

#block-views-vue-appli-mag-block{
	width: 100%;;
	.view-content{
		@include flexbox;
		@include justify-content(space-between);
	}
	@include bp(min, $tablet){
		width: 500px;
	}
}

/* Bottom footer */
.bottom-footer{
	@include flexbox;
	@include align-items(center);
	@include flex-wrap(wrap);
	@include justify-content(center);
	padding: 0 10px;
	@include bp(min, $tablet){
		padding: 30px 0px 0 0px;
		@include justify-content(flex-start);
	}
}
.region-bottomfooter{
	width: 100%;
	@include bp(min,$tablet){
		width: 400px;
	}
}
#block-menu-menu-menu-{
	width: 100%;
	.menu{
		@include flexbox;
		@include justify-content(space-around);
	}
	a{
		display: inline-block;
		@include fontsize(15);
		font-family: $font-title;
		text-transform: uppercase;
		@include transition(all 0.3s ease);
		&:hover{
			text-decoration: underline;
		}
	}
	@include bp(min, $tablet){
		width: 350px;
	}
}

#footer-ville{
	margin-left: 30px;
	margin-top: 20px;
	select{
		padding: 5px 10px;
		background-color: $grey;
		border: 1px solid $grey;
		text-transform: uppercase;
	}
	@include bp(min, $tablet){
		margin-top: 0;
	}
}
