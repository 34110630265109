/* Burger menu */
.burger {
	display: block;
	position: relative;
	height: 40px;
	width: 40px;
	z-index: 10;
	outline: none;
	line-height: 85px;
	text-transform: uppercase;
	color: white;
	span {
		width: 24px;
		height: 4px;
		display: block;
		background: $dark-grey;
		position: absolute;
		border-radius: 4px;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
		@include transition(all 0.2s ease);
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0px;
			left: 0px;
			border-radius: 4px;
			display: block;
			width: 24px;
			height: 4px;
			@include transform(translateY(-8px));
			background: $dark-grey;
			@include transition(all 0.2s ease);
		}
		&:after {
			@include transform(translateY(8px));
		}
	}
	&.menu-open span:before {
		@include transform(rotate(45deg));
	}
	&.menu-open span:after {
		@include transform(rotate(-45deg));
	}
	&.menu-open span {
		background: transparent;
	}
	@include bp(min, $normal){
		display: none;
	}
}


.nav{
	position: relative;

	@include bp(min, $normal){
		margin: 45px auto 0px auto;
		.menu{
			display: flex;
			>li{
				list-style: none;
				margin: 0;
				padding: 0;
				>a{
					position: relative;
					display: block;
					padding: 0;
					font-family: $font-title;
					@include fontsize(18);
					@include lazylinks($dark-grey);
					text-transform: uppercase;
					font-weight: 700;
					text-align: center;
					&:after{
						content: "";
						display: inline-block;
						position: absolute;
						right: -2px;
						bottom: 0;
						width: 2px;
						height: 24px;
						background-color: $grey;
					}
				}
			}
		}
	}

	@include bp(min, 1200px){
		.menu{
			>li{
				>a{
					padding: 8px 20px;
					@include transition(all 0.3s ease);
					&.active{
						@include lazylinks(white);
						background-color: $orange;
						z-index: 25;
					}
				}
				&:first-child>a:before{
					 content: "";
					 display: inline-block;
					 position: absolute;
					 left: -2px;
					 bottom: 0;
					 width: 2px;
					 height: 24px;
					 background-color: $grey;
				}
			}
		}
	}
}


.mega-menu{
	/*padding: 20px 55px;*/
	&.active{
		@include flexbox;
	}
	display: none;
	@include flex-wrap(wrap);
	position: absolute;
	left: 50%;
	top: 42px;
	@include transform(translate(-50%,0));
	width: 100%;
	max-width: 1200px;
	z-index: 20;
	text-align: left;
	background-color: white;
	-webkit-box-shadow:  0px 2px 3px 0 rgba(180, 180, 180, 0.75);
	-moz-box-shadow:  0px 2px 3px 0 rgba(180, 180, 180, 0.75);
	box-shadow: 0px 2px 3px 0 rgba(180, 180, 180, 0.75);
	.title{
		@include fontsize(15);
		text-transform: uppercase;
		color: $grey-99;
		@include lazylinks($grey-99);
		font-family: $font-title;
	}
	.left{
		position: relative;
		width: 55%;
		padding: 20px 40px;
		background-color: white;
		max-height: 450px;
		overflow-x: auto;
		.categ{
			@include calc(width, '100% / 3');
			float: left;
			ul{
				padding-left: 10px;
				margin-bottom: 20px;
			}
			.title{
				margin-bottom: 12px;
			}
			a{
				display: block;
				position: relative;
				@include links($dark-grey, $dark-grey, white, $dark-grey);
				@include fontsize(16);
				padding: 3px 0;
				@include transition(all 0.4s ease);
				background-color: transparent;
				&:before{
					content: "\2022";
					display: inline-block;
					position: absolute;
					left: -25px;
					top: 0px;
					width: 25px;
					height: 100%;
					padding-left: 5px;
					color: $orange;
					@include fontsize(38);
					line-height: 24px;
					@include transition(all 0.4s ease);
					background-color: transparent;
				}
				&:hover{
					background-color: $orange;
					&:before{
						color: white;
						background-color: $orange;
					}
				}
			}
		}
	}
	.right{
		width: 45%;
		padding: 20px 40px;
		background-color: $dark-grey;
		p{
			color: $light-grey;
			margin-top: 2px;
		}
		li{
			@include flexbox;
			margin : 20px 0 5px 0;
			.title{
				@include lazylinks(white);
			}
			a{
				position: relative;
				display: inline-block;
				margin-top: 12px;
				text-transform: uppercase;
				@include lazylinks(white);
				@include fontsize(13);
				&:before{
					content: "";
					position: absolute;
					bottom: -2px;
					width: 100%;
					height: 2px;
					background-color: $grey-99;
				}
			}
		}
		img{
			width: 160px;
			max-height: 90px;
			margin-right: 15px;
		}
		.ctn-promo{
			@include calc(width,'100% - 160px');
		}
	}
	.list-promo{
		@include flexbox;
		@include flex-direction(column);
		@include flex-wrap(wrap);
	}
}



#block-menu-menu-menu-principal-mobile{
	display: none;
	position: absolute;;
	top: -1px;
	width: 100%;
	z-index: 50;
	.menu{
		a{
			display: block;
			text-align: center;
			padding: 6px 0 4px 0;
			text-transform: uppercase;
			font-family: $font-title;
			@include lazylinks($dark-grey);
			background-color: $lighty-grey;
			border-bottom: 1px solid white;
		}
		.myWik{
			background-color: white;
			@include lazylinks($red);
			border-bottom: 1px solid $lighty-grey;
		}
		li{
			&:first-child{
				a{
					padding-top: 10px;
				}
			}
			&:last-child{
				a{
					background-color: white;
					padding-bottom: 10px;
				}
			}
		}
	}
}

