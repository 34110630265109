.top-header{
	background-color: $dark-grey;
	color: $grey;
	a{
		@include lazylinks($grey);
		font-family: $font-title;
		font-weight: 700;
	}
	.view-publicites{
		margin: 0 auto;
		padding: 10px 0;
    	border-bottom: 1px solid black;
		background-color: transparent;
		img{
			border: 1px solid #424242;
		}
	}
}

.pub-top{
	height: 110px;
	border-bottom: 1px solid #080808;
	padding: 25px 0;
	p{
		display: block;
		margin: 0 auto;
		padding: 10px 20px;
		background-color: white;
		@include fontsize(16);
		color: black;
		width: 125px;
		text-align: center;
	}
}

#bloc-reseau-appli{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 7px 10px;
	@include flexbox;
	@include align-items(center);
	@include justify-content(flex-start);
	@include flex-wrap(wrap);
	@include fontsize(14);
	@include bp(min, $tablet){
		@include justify-content(flex-end);
		height: 40px;
		padding: 12px 0 5px 0;
		@include fontsize(15);
	}
}

#connexion{
	a{
		text-decoration: underline;
	}
	@include bp(min, $tablet){
		margin-left: 55px;
	}
}

#bloc-reseau{
	@include flexbox;
	@include align-items(center);
	font-family: $font-title;
	a{
		display: inline-block;
		width: 20px;
		height: 20px;
		@include fontsize(14);
	}
	.twitter a{
		background: url(../img/logo/logo-twitter.svg);
		background-size: 20px;
	}
	.facebook a{
		background: url(../img/logo/logo-facebook.svg);
		background-size: 20px;
		margin: 0 5px 0 10px;
	}
}


.region-header{
	width: 70%;
	display: none;
	@include bp(min, $normal){
		@include flexbox;
		@include align-items(center);
		@include justify-content(space-around);
	}
}

#header{
	position: relative;
	background-color: white;
	z-index: 25;
	.bloc-appli{
		width: 257px;
		height: 61px;
	}
	.bloc-magazine{
		width: 167px;
		height: 65px;
	}
	@include bp(min, $normal){
		padding : 25px 0 5px 0;
	}
	&:before{
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		-webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.75);
		z-index: 21;
	}
}

#block-wikmotion-m-wikmotion-m-submenu{
	display: none;
	@include bp(min, $normal){
		display: block;
	}
	.content{
		>.menu{
			@include flexbox;
			@include justify-content(space-between);
			li{
				z-index: 5;
				@include flex(1);
				@include transition(all 0.3s ease);
				&:hover{
					color: white;
					&:before{
						content: "";
						height: 40px;
					}
				}
			}
		}
	}
}


.header-middle{
	@include flexbox;
	@include justify-content(space-between);
	@include flex-wrap(wrap);
	@include align-items(center);
	padding: 10px;
	@include bp(min, $tablet){
		padding: 0;
	}
}
#block-views-vue-appli-mag-block-1{
	.view-content{
		width: 100%;
		max-width: 450px;
		@include flexbox;
		@include justify-content(space-between);
	}
}

#name-and-slogan{
	display: none;
}
#logo{
	display: inline-block;
	@include bp(max, $tablet){
		width: 150px;
		margin: 5px;
	}
	@include bp(max, $normal){
		width: 200px;
		margin: 10px;
	}
}

#block-custom-search-blocks-1{
	width: 100%;
	max-width: 300px;
	margin-right: 4vw;
	form{
		>div{
			position: relative;
		}
	}
	.form-item{
		position: relative;
		margin: 0;
        padding: 12px 10px;
		border: 2px solid #f2f2f2;
	}
	input{
		width: 100%;
		border: none;
	}
	.form-actions{
		margin: 0;
	}
	//Conteneur btn submit
	#edit-actions,
	#edit-actions--2{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		@include fontsize(0);
		&:before{
			content: "";
			width: 25px;
			height: 26px;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translate(0,-50%);
			background: url(../img/svg/picto_loupe.svg) no-repeat center;
			background-size: contain;
		}

	}
	//Btn submit
	#edit-submit,
	#edit-submit--2{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 50px;
		background-color: transparent;
		z-index: 10;
		cursor: pointer;
	}
}
.search-form{
	position: relative;
	margin: 0;
}

.header-right{
	@include flexbox;
	@include align-items(center);
}







/* Bloc random de connexion à l'admin */
#block-block-4{
	display: none;
}




