.btn {
  display: inline-block;
  padding: 0 30px;
  line-height: 30px;
  letter-spacing: 0.1em;

  background-color: aliceblue;
  border: 1px solid aliceblue;

  font-family: $font-title;
  @include fontsize(15);
  font-weight: 500;
  text-transform: uppercase;
  a {
    color: aliceblue;
    text-decoration: none;
  }
  &:hover {
    background-color: transparent;
    transition: $transition-base;

    a {
      color: aliceblue;
      text-decoration: none;
    }
  }
}

.btn_light {
  display: inline-block;
  margin: 30px 0 32px 0;
  padding: 0 40px;
  line-height: 35px;

  border: 1px solid aliceblue;
  border-radius: 12px;

  font-family: $font-title;
  @include fontsize(14);
  font-weight: 400;

  text-transform: uppercase;
  color: aliceblue;
  text-decoration: none;
  &:hover {
    background-color: aliceblue;
    transition: $transition-base;
    text-decoration: none;
  }
}