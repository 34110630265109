body.front{
	background: white;
}

.swiper-container{
	width: 100%;
	@include bp(min, $laptop){
		max-width: 1200px;
	}
}
.blur-bg{
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	height: 400px;
	width: 100%;
	filter: blur(5px);
	-webkit-filter: blur(5px);
	opacity: 0.5;
}
#block-views-vue-slider-home-block{
	position: relative;
	overflow: hidden;
}
.sliderHome{
	height: 40vw;
	.field-content{
		background-color: aliceblue;
	}
	.swiper-slide{
		position: relative;
		img{
			width: 100%;
		}
	}
	.content{
		position: absolute;
		left: 0;
		bottom: 3px;
		background-color: rgba(255,255,255,0.7);
	}
	.title{
		padding: 5px;
		@include fontsize(20);
		background-color: white;
		color: black;
		a{
			@include lazylinks(black);
		}
	}
	.desc{
		display: none;
	}
	@include bp(min, $normal){
		height: 40vw;
		max-height: 500px;
		.content{
			right: 0;
			left: auto;
			top: 50%;
			bottom: auto;
			@include transform(translate(0,-50%));
			width: 500px;
			background-color: rgba(255,255,255,0.82);
		}
		.title{
			padding: 5px;
			@include fontsize(37);
		}
		.desc{
			display: block;
			padding: 12px;
			a{
				position: relative;
				float: right;
				text-align: right;
				margin: 12px 0 15px 0;
				font-family: $font-title;
				font-weight: 700;
				text-transform: uppercase;
				color: $red;
				&:before{
					content: "";
					width: 11px;
					height: 11px;
					background: url(../img/picto/picto_triangle_red.svg);
					background-size: cover;
					display: inline-block;
					position: absolute;
					top: 2px;
					left: -15px;
				}
				&:after{
					content: "";
					position: absolute;
					left: 0;
					bottom: -1px;
					width: 100%;
					height: 1px;
					@include fontsize(17);
					background-color: $red;
				}
			}
		}
		//Masque element suivant prev desktop
		.swiper-button-prev,
		.swiper-button-next{
			display: none;
		}
	}
}

#block-views-selection-de-wik-block,
.view-mywik-promouvoir,
.view-mywik-home{
	@include wrap1200;
	margin: 30px auto 30px auto;
	@include bp(min, $normal){
		margin-top: 45px;
	}
}

/* selection wik */
.vue-selection-home,
.vue-festival-rdv,
.view-mywik-promouvoir,
.view-mywik-home,
.view-rencontres-de-wik{
	.views-row{
		width: 90%;
		margin: 10px auto;
	}
	.title {
		display: block;
		width: 100%;
	}
	.ctn{
		a{
			&:after{
				width: 175px;
			}
		}
	}
	.btn-link{
		@include links($grey, $grey, white, $grey);
	}
	&:after{
		content: "";
		display: block;
		clear: both;
	}

	//Fix image manquante
	.views-field-field-evt-affiche,
	.field-content,
	.ctn-wrap{
		height: 100%;
	}
	.views-field-field-evt-affiche{
		background-color: #ebebee;
	}

	@include bp(min, $tablet){
		.views-row{
			width: 25%;
			float: left;
			padding: 0 12px;
			margin: 12px 0;
		}
		.views-row.views-row-first{
			width: 50%;
		}
	}
	@include bp(min, $normal){
		.views-row.views-row-first{
			height: 430px;
		}

	}
	@include bp(min, 1100px){
		.views-row{
			height: 250px;
		}
		.views-row.views-row-first{
			height: 520px;
		}
	}
}

/* Commun selection wik et vue interview/rencontres */
.vue-rencontres-home,
.vue-selection-home,
.vue-festival-rdv,
.view-mywik-promouvoir,
.view-mywik-home{
	.ctn-wrap{
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}
	.h2{
		margin-left: 12px;
	}
	img{
		width: 100%;
	}
	.view-footer{
		position: relative;;
		width: 90%;
		clear: both;
		text-align: center;
		margin: 0 auto;
		a{
			margin-top: 28px;
			@include transition(all 0.3s ease);
		}
	}
	@include bp(min, $tablet){
		.view-footer{
			width: auto;
		}
	}
}

.discover {
	position: absolute;
	bottom: 0;
	width: 100%;
	max-height: 100%;
	overflow: hidden;
	color: $dark-grey;
	background-color: rgba(255, 255, 255, .88);
	@include bp(min, 1025px) {
		@include transition(all .35s ease);
	}
	&:hover {
		.title{
			border-left: 6px solid transparent;
		}
		.ctn{
			position: relative;
			bottom: 0;
			opacity: 1;
		}
	}
	.title {
		display: block;
		padding: 10px;
		font-family: $font-primary;
		@include fontsize(14);
		border-left: 6px solid $red-select;
		font-weight: bold;
	}
	>a {
		display: inline-block;
		padding: 8px 20px;
		margin: 15px 0;
		border-radius: 20px;
		background-color: red;
		color: white;
		position: absolute;
		opacity: 0;
		bottom: -200px;
		@include bp(min, 1025px) {
			@include transition(all .35s ease);
		}
	}
	.ctn{
		position: absolute;
		bottom: -200px;
		opacity: 0;
		@include transition(all .35s ease);
		span,
		p{
			display: block;
			padding: 0 16px;
			margin-bottom: 10px;
		}
		a{
			display: block;
			position: relative;
			padding: 10px;
			text-align: center;
			background-color: $red-select;
			@include lazylinks(white);
			text-transform: uppercase;
			font-family: $font-title;
			@include fontsize(16);
			&:after{
				content: "";
				position: absolute;
				left: 50%;
				bottom: 7px;
				@include transform(translate(-50%,0));
				width: 175px;
				height: 2px;
				@include fontsize(16);
				background-color: rgba(255,255,255,0.5);
			}
		}
	}
}

/* Vue Rencontre/interview */
#block-views-rencontres-de-wik-block{
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;
	&:before{
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 56px;
		background: url(../img/bg/bg_stripes.png);
	}
}
.vue-rencontres-home{
	@include wrap1200;
	margin-bottom: 30px;
	.views-row{
		width: 90%;
		margin: 10px;
	}
	h2{
		margin-bottom: 40px;
	}
	.title{
		display: inline-block;
		margin: 0;
	}
	.ctn{
		a{
			&:after{
				width: 175px;
				width: 11.5rem;
			}
			&:before{
				content:"";
				position: absolute;
				left: -7px;
				top: 4px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 16px 0 16px 24px;
				border-color: transparent transparent transparent #ffffff;
			}
		}
	}
	@include bp(min, $tablet){
		.view-content{
			@include flexbox;
			@include justify-content(space-between);
		}
		.views-row{
			width: 31%;
		}
	}
}

.vue-festival-rdv{
	@include wrap1200;
	margin-bottom: 30px;
	.ctn{
		a{
			&:after{
				width: 110px;
				width: 11rem;
			}
		}
	}
}

#agenda-wik{
	padding: 40px 0;
	background: url(../img/bg/bg_stripes.png);
	.ctn-content{
		@include wrap1200;
		@include flexbox;
		@include justify-content(space-between);
		@include flex-wrap(wrap);
	}
	.h2{
		width: 100%;
		margin: 0 0 20px 12px;
	}
	.days,
	.links{
		width: 48%;
		li{
			height: 40px;
			background-color: white;
			margin-bottom: 8px;
			@include transition(all 0.3s ease);
			@include box-shadow(0, 0, 0, 0, rgba(173,173,173,1));
			&:hover{
				@include box-shadow(0, 0, 9px, 0px, rgba(173,173,173,1));
			}
		}
		a{
			position: relative;
			display: block;
			@include flexbox;
			@include align-items(center);
			height: 100%;
			padding: 0 0 0 32px;
			font-weight: bold;
			text-transform: uppercase;
			@include fontsize(13);
			&:before{
				content: "";
				position: absolute;
				left: 0px;
				top: 50%;
				@include transform(translate(0,-50%));
				width: 26px;
				height: 26px;
				background-size: cover;
			}
		}
	}
	.days{
		a{
			@include lazylinks($dark-grey);
		}
	}
	.today:before{
		background: url(../img/picto/picto_cible.png) no-repeat center;
	}
	.tomorrow:before{
		background: url(../img/picto/picto_fleche_descendante.png) no-repeat center;
	}
	.week-end:before{
		background: url(../img/picto/picto_week_end.png) no-repeat center;
	}
	.week:before{
		background: url(../img/picto/picto_rond_rond_rond.png) no-repeat center;
	}
	.concert:before{
		background: url(../img/picto/picto_do_re.png) no-repeat center;
	}
	.show:before{
		background: url(../img/picto/picto_projo.png) no-repeat center;
	}
	.leisure:before{
		background: url(../img/picto/picto_point_parenthese.png) no-repeat center;
	}
	.expo:before{
		background: url(../img/picto/picto_formes_dans_cadre.png) no-repeat center;
	}
	.family:before{
		background: url(../img/picto/picto_famille_yoda.png) no-repeat center;
	}
	.red{
		color: $red-select;
		@include lazylinks($red-select);
	}
	.yellow{
		color: $yellow;
		@include lazylinks($yellow);
	}
	.purple,
	.violet{
		color: $purple;
		@include lazylinks($purple);
	}
	.green{
		color: $green;
		@include lazylinks($green);
	}
	.aqua{
		color: $aqua;
		@include lazylinks($aqua);
	}
	.orange{
		color: $orange;
		@include lazylinks($orange);
	}
	@include bp(min, $tablet){
		.h2{
			margin-bottom: 24px;
		}
		.days,
		.links{
			width: 30%;
			li{
				display: block;
				height: auto;
			}
			a{
				padding: 15px 0 15px 100px;
				@include fontsize(15);
				&:before{
					width: 42px;
					height: 42px;
					left: 26px;
				}
				&:after{
					content: "";
					position: absolute;
					left: 85px;
					top: 50%;
					@include transform(translate(0,-50%));
					width: 1px;
					height: 26px;
					background-color: #DDD;
				}
			}
		}
	}
}


//Bloc de recherche bottom home
#search-wik{
	width: 100%;
	.title{
		display: block;
		background-color: white;
		padding: 15px 0 15px 10px;
		margin-bottom: 15px;
		color: black;
		@include fontsize(20);
	}
	@include bp(min, $tablet){
		width: 280px;
		.title{
			@include fontsize(26);
		}
	}
}

#block-wik-footer-search{
	input,
	select{
		width: 100%;
		border-top: none;
	}
	#edit-category,
	#edit-category--2{
		border-top: 1px solid $light-grey;
	}
	.form-submit{
		background-color: $red-select;
		border: 1px solid $red-select;
	}
}

#block-wik-sidebar-search{
	form{
		margin-bottom: 30px;
		.form-item{
			margin: 0 auto;
		}
		input,
		select{
			width: 100%;
			border-top: none;
		}
		#edit-category,
		#edit-category--2{
			border-top: 1px solid $light-grey;
		}
		.form-item--submit{
			&:before{
				bottom: 16px;
			}
		}
		.form-submit{
			padding: 12px 40px;
			border: 1px solid $red-select;
			background-color: $red-select;
		}
	}
}



/* triple block jeux, newsletter, application */
#block-wikmotion-wikmotion-triple-block,
#block-wikmotion-m-wikmotion-m-triple-block{
	@include wrap1200;

	.content{
		>div{
			width: 90%;
			margin: 0 auto;
		}
	}

	#bloc-appli,
	#block-jeux,
	#block-newsletter{
		margin: 15px auto;
	}


	#bloc-appli{
		@include flexbox;
		@include flex-wrap(wrap);
		@include justify-content(space-between);
		.description{
			width: 215px;
			margin: 0 auto;
			padding-left: 60px;
			&:before{
				left: 0px;
				top: -2px;
			}
		}

		@include bp(min, $tablet) {
			.description{
				@include calc(padding-left, '18% + 60px');
				&:before{
					left: 18%;
				}
			}
		}
	}

	#block-jeux{
		@include flexbox;
		@include flex-wrap(wrap);
		position: relative;
		overflow: hidden;

		.link{
			position: absolute;
			right: 0;
			bottom: 20px;
			width: 115px;
			padding: 16px 20px 16px 32px;
		}
		.description{
			width: 55%;
		}
	}
	@include bp(min, $tablet) {
		padding: 50px 0;

		#bloc-appli,
		#block-jeux,
		#block-newsletter{
			margin: 0;
		}

		.content{
			@include flexbox;
			@include justify-content(space-between);
			>div{
				width: 31%;
				padding: 16px;
			}
		}
	}
}


//MyWik bottom
.sliderMyWikHome{
	width: 90%;
	margin-bottom: 30px;
	.views-field-nothing{
		width: 18%;
	}
	.ctn-wrap{
		position: relative;
	}
}






















